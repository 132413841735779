import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo/Seo";
import Content, { HTMLContent } from "../components/Content";
import bannerDummy from "../../static/img/banner_image_logo.png";

export const BlogPostTemplate = ({
  image,
  content,
  contentComponent,
  description,
  tags,
  title,
}) => {
  const PostContent = contentComponent || Content;

  let featuredImage = null;

  if (image) {
    featuredImage = `url(${
      !!image.childImageSharp ? image.childImageSharp.fluid.src : image
    })`;
    featuredImage = featuredImage.includes("/logo.png") ? "" : featuredImage;
  }

  return (
    <>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: featuredImage,
          backgroundPosition: `center`,
          backgroundAttachment: `fixed`,
        }}
      >
        {!featuredImage ? (
          <div
            style={{
              backgroundImage: `url(${bannerDummy})`,
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
      <section className="section">
        <div style={{ margin: "30px" }}>
          <Link to={`/blog`}> ← Zurück zur Übersicht</Link>
        </div>
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <p>{description}</p>
              <PostContent content={content} />
              {tags && tags.length ? (
                <div style={{ marginTop: `2rem` }}>
                  <div className="tags">
                    {tags.map((tag) => (
                      <Link
                        key={tag + `tag`}
                        to={`/tags/${kebabCase(tag)}/`}
                        className="tag is-primary"
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

BlogPostTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;

  const image = post.frontmatter.featuredimage
    ? post.frontmatter.featuredimage.childImageSharp.resize
    : null;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={image}
        pathname={location.pathname}
      />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        image={post.frontmatter.featuredimage}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
